import React, { useContext, useState, useEffect } from 'react';
import AuthContext from '../context/AuthContext';
import { handleGoogleSignIn } from '../api/userApi';
import {Alert, AlertDescription} from '../components/ui/alert'
import { jwtDecode } from 'jwt-decode';

const LoginPage = () => {
  let { loginUser, loginGoogleUser } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: "104726586787-t2iljkip440s1ifhurg62dkk4bbjdj3i.apps.googleusercontent.com",
        callback: async (response) => {
          const data = await handleGoogleSignIn(response);
          if (data) {
            loginGoogleUser(data);
          }
        }
      });
      window.google.accounts.id.renderButton(
        document.getElementById("googleSignInDiv"),
        { theme: "outline", size: "large" }
      );
    }


  }, []);




  const handleSubmit = e => {
    e.preventDefault();
    loginUser(e)
      .then(() => {
        // Login successful
      })
      .catch(error => {
        setError(error.message);
      });
  };

  return (
    <div className="flex justify-center items-center h-screen bg-[#1E2A4A]">
      <div className="bg-[#152454] shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4 w-full max-w-md text-white">
        <div className="text-center mb-8">
          <img src="/logo-white-peercards.png" alt="Peercards" className="mx-auto h-12 md:h-16 w-auto" />
          <p className="mt-4 text-base md:text-lg font-light">
            Version <span className="font-bold">1.0.0</span>
          </p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-300">
              Email/Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="mt-1 block w-full px-3 py-2 bg-[#1E2A4A] border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-[#457B9D] focus:border-[#457B9D] sm:text-sm text-white"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-300">
              Password
            </label>
            <div className="relative mt-1">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                className="block w-full px-3 py-2 bg-[#1E2A4A] border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-[#457B9D] focus:border-[#457B9D] sm:text-sm text-white"
              />
              <span
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <XIcon className="h-5 w-5 text-gray-400" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-400" />
                )}
              </span>
            </div>
          </div>
          <div>
            <button type="submit" className="w-full bg-white text-black py-2 rounded-md  transition duration-300">
              Login
            </button>
          </div>
        </form>
        {error && (
          <Alert variant="destructive" className="mt-6">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <div id="googleSignInDiv" className="mt-6 w-full"></div>
      </div>
    </div>
  );}

function EyeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
      <circle cx="12" cy="12" r="3" />
    </svg>
  );
}

function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
}

export default LoginPage;